import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import { graphql, useStaticQuery, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { breakpoints, BigHeader, BuyNowLink } from 'src/utils/styles/global-styles'
import {
  HIDE_PLUS,
  BUY_LINK,
  CAL_BUY_LINK,
  IS_FOREIGN_COUNTRY,
  IN_CA,
  IN_AU,
  IN_UK,
} from 'src/utils/constants'

function Content(props) {
  const { name, image, link, videoLink, alt, text } = props
  return (
    <Card>
      {videoLink ? (
        <Video playsInline autoPlay muted loop>
          <source src={videoLink} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
      ) : (
        <Link to={link}>
          <ImageContainer>
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={alt} />
          </ImageContainer>
        </Link>
      )}
      <TextBox>
        <ProductName>
          <Trans>{name}</Trans>
        </ProductName>
        <ProductDescription>
          <Trans>{text}</Trans>
        </ProductDescription>
      </TextBox>
      <ButtonContainer>
        <BuyNowLink to={link}>
          <Trans>Shop Now</Trans>
        </BuyNowLink>
      </ButtonContainer>
    </Card>
  )
}

const ProductsList = () => {
  const { frameComparison, calendar, calendar15, calendarPlus } = useStaticQuery(graphql`
    query ProductsListQuery {
      frameComparison: file(relativePath: { eq: "frame-comparison.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      calendar: file(relativePath: { eq: "calendar-comp.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calendar15: file(relativePath: { eq: "calendar/cal-details.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calendarPlus: file(relativePath: { eq: "cal-plus-screensaver.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const usProducts = [
    {
      name: 'Skylight Frame',
      image: frameComparison,
      link: `${BUY_LINK}`,
      alt: 'Skylight Frame with image of child eating a strawberry ',
      text: `Share memories with loved ones anytime, from anywhere.`,
    },
    {
      name: 'Skylight Calendar',
      image: IS_FOREIGN_COUNTRY ? calendar15 : calendar,
      link: `${CAL_BUY_LINK}`,
      alt: 'Skylight Calendar displayed on a work desk with pretty succulents beside it',
      text: `Display all of your events on one simple, touchscreen device.`,
    },
    {
      name: 'Frame Plus',
      link: '/products/skylight-plus/',
      videoLink: '//skylight-marketing-assets.s3.amazonaws.com/bike.mp4',
      alt: 'Video running on the Skylight Frame of a child riding a bike',
      text: `Upgrade your Skylight Frame experience with albums, videos, captions, and more.`,
    },
    // {
    //   name: 'Calendar Plus',
    //   image: calendarPlus,
    //   link: '/products/calendar-skylight-plus/',
    //   alt: 'Photo screensaver shown on the Skylight Calendar',
    //   text: `Turn your Calendar into a photo frame and seamlessly pull in events from emails or PDFs with Magic Import`,
    // },
  ]
  let internationalProducts = []
  if (HIDE_PLUS) {
    internationalProducts = [usProducts[0]]
  } else if (IN_CA || IN_AU || IN_UK) {
    // internationalProducts = [usProducts[0], usProducts[1], usProducts[2], usProducts[3]]
    internationalProducts = [usProducts[0], usProducts[1], usProducts[2]]
  } else {
    internationalProducts = [usProducts[0], usProducts[2]]
  }
  const products = IS_FOREIGN_COUNTRY ? internationalProducts : usProducts
  return (
    <>
      <NavigationBar>
        <NavLink to={!IS_FOREIGN_COUNTRY ? '/frame/' : '/'}>
          <Trans>Frame</Trans>
        </NavLink>
        {(IN_AU || IN_CA || IN_UK || !IS_FOREIGN_COUNTRY) && (
          <NavLink to="/calendar/">
            <Trans>Calendar</Trans>
          </NavLink>
        )}
        {!HIDE_PLUS && (
          <NavLink to="/products/skylight-plus/">
            <Trans>Frame Plus</Trans>
          </NavLink>
        )}
        {/* {(IN_AU || IN_CA || IN_UK || !IS_FOREIGN_COUNTRY) && (
          <NavLink to="/products/calendar-skylight-plus/">
            <Trans>Calendar Plus</Trans>
          </NavLink>
        )} */}
      </NavigationBar>
      <Section>
        {products.map((product, i) => (
          <Content
            key={i}
            name={product.name}
            image={product.image}
            link={product.link}
            videoLink={product.videoLink}
            alt={product.alt}
            text={product.text}
          />
        ))}
      </Section>
    </>
  )
}
Content.propTypes = {
  name: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.string,
  videoLink: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string,
}

export default ProductsList

const NavigationBar = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 30px;
    flex-wrap: wrap;
  }
`
const LinksCSS = css`
  margin: 1.5rem 1.5rem 1.5rem 0px;
  color: ${(props) => props.theme.grayDark};
  text-decoration: none;
  padding-bottom: 0.05rem;
  border-bottom: 2px solid ${(props) => props.theme.coral};
  :hover {
    color: ${(props) => `${lighten(0.1, props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 5px 20px 5px 0px;
  }
`
const NavLink = styled(Link)`
  ${LinksCSS}
`
export const SupportLink = styled.a`
  ${LinksCSS}
`

export const Section = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
export const Card = styled.div`
  flex: 0 1 45%;
  margin: 20px 20px 20px 0px;
  text-align: center;
  padding-bottom: 60px;
  position: relative;
  @media (max-width: ${breakpoints.l}px) {
    flex: 0 1 80%;
  }
  @media (max-width: ${breakpoints.s}px) {
    flex: 0 1 100%;
    margin: 0px;
    padding-bottom: 60px;
  }
`
export const Message = styled.p`
  width: 500px;
  max-width: 90%;
`
export const Header = styled.h1`
  margin: 3rem 0px 1rem 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 32px;
  }
`
export const ImageContainer = styled.div`
  width: 100%;
  @media (max-width: ${breakpoints.l}px) {
    width: 70%;
    margin: auto;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
`
export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  @media (max-width: ${breakpoints.l}px) {
    position: unset;
  }
`

export const TextBox = styled.div`
  padding: 20px 0px 40px;
  @media (max-width: ${breakpoints.l}px) {
    width: 70%;
    margin: auto;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 15px 0px 30px;
  }
`
export const Video = styled.video`
  width: 100%;
  padding-top: 55px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: auto;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding-top: 0px;
    width: 70%;
    margin: auto;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
`
export const ProductName = styled(BigHeader)`
  margin: 10px 0px;
  text-align: center;
`
export const ProductDescription = styled.p`
  width: 350px;
  margin: auto;
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`
